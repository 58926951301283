import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Spin, Modal, Skeleton } from 'antd';
import { useTranslations } from '@veraio/strank';
import { Button, Tag, Pagination } from 'components/UIExternal';
import DeclineModal from 'components/ui/DeclineModal';
import CommentModal from 'components/ui/CommentModal';
import ImageGalleryPanel from 'components/ui/ImageGalleryPanel';
import { Icon, LoadMoreText } from 'components/ui';
import { isNil } from '@veraio/core';
import { disputeStatus, disputeStatusColor, disputeStatusInverse } from 'enums';
import { formatDefaultDateTime, useParamsInt } from 'utils';
import useError from 'services/errorHandling/useError';
import { getDisputeById, updateDisputeStatus, addCommentToDispute, getDisputeComments } from 'services';
import { mb } from 'styles';
import {
  spinner,
  navigationWrapper,
  userDetailsWrapper,
  userDetailsLine,
  subtitle,
  avatar,
  nameDateWrapper,
  descriptionWrapper,
  disputeImgBtnStyle,
  galleryModal,
  noAvatar,
} from './styles';

const DisputeDetail = () => {
  const history = useHistory();
  const { getText, getDynamicTranslation } = useTranslations();
  const disputeId = useParamsInt('disputeId');
  const { setError } = useError();
  const [dispute, setDispute] = useState();
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [isDeclineModalVisible, setIsDeclineModalVisible] = useState(false);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const paginationRef = useRef();
  const [ disputeComments, setDisputeComments ] = useState(null);

  const filterKeys = {
    search: 'searchText',
    status: 'statusId',
    business: 'businessId',
    sorting: 'dealsSorting',
  };

  useEffect(() => {
    fetchDispute();
  }, [disputeId]);

  const fetchDisputeComments = async options => {
    const [res, err] = await getDisputeComments(disputeId, options);
    err ? setError(err) : setDisputeComments(res);
    return res;
  };

  const fetchDispute = async () => {
    const [res, err] = await getDisputeById(disputeId);
    err ? setError(err) : setDispute(res);
  };

  const handleEditDisputeStatus = async (status, reason) => {
    const [, err] = await updateDisputeStatus({ id: disputeId, status, reason });
    err ? setError(err) : fetchDispute();
    setIsDeclineModalVisible(false);
  };

  const handleAddCommentToDispute = async (comment) => {
    const [, err] = await addCommentToDispute({ id: disputeId, comment });
    err ? setError(err) : fetchDispute();
    setIsCommentModalVisible(false);
    fetchDisputeComments({
      pageSize: 5,
      pageNumber: 1
    });
  };

  const disputeCommentsContent =
    disputeComments?.items?.length > 0 && (
      <Row css={mb({ lg: 20, span: 30 })}>
        {disputeComments.items.map((disputeComment) => (
          <Col span={24} key={disputeComment.createdDate}>
            <p>
              <span className="bolder-text">{`${disputeComment.managerName} [${formatDefaultDateTime(
                disputeComment.createdDate,
              )}]: `}</span>
              {disputeComment.comment.length > 80 ?
                <LoadMoreText text={disputeComment.comment} limit={80} /> :
                disputeComment.comment}
            </p>
          </Col>
        ))}
      </Row>
  );

  return dispute ? (
    <>
      <div className="inner-content-container">
        <div css={navigationWrapper}>
          <div className="specs-container">
            <Button type="link" onClick={() => history.goBack()} small>
              <Icon iconName="las la-arrow-left" />
            </Button>
            <h2 className="flex flex-center deal-name">{getDynamicTranslation(dispute.dealTitle)?.title}</h2>
            <Tag type={disputeStatusColor[dispute.statusId]}>{getText(disputeStatusInverse[dispute.statusId])}</Tag>
          </div>
          <div className="actions-container">
            {dispute.statusId !== disputeStatus.approved && (
              <Button type="secondary" small onClick={() => handleEditDisputeStatus(disputeStatus.approved)}>
                {getText('approveDispute')}
              </Button>
            )}
            <Button type="secondary" small onClick={() => setIsCommentModalVisible(true)}>
              {getText('comment')}
            </Button>

            {dispute.statusId !== disputeStatus.declined && (
              <Button type="danger" small onClick={() => setIsDeclineModalVisible(true)}>
                {getText('declineDispute')}
              </Button>
            )}
          </div>
        </div>
        <Col lg={{ span: 19, order: 1 }} span={24}>
          <Pagination ref={paginationRef} onChange={fetchDisputeComments} pageSize={5} filterKeys={filterKeys} />
          {isNil(disputeComments) ? <Skeleton count={4} width="24%" height={400} /> : disputeCommentsContent}
        </Col>
        <Row css={userDetailsLine}>
          <Col span={24} lg={5}>
            <div css={userDetailsWrapper}>
              <Icon iconName="las la-shopping-bag" />
              <label>{dispute.userNames}</label>
            </div>
          </Col>
          <Col span={24} lg={4}>
            <div css={userDetailsWrapper}>
              <Icon iconName="las la-phone-alt" />
              <label>{dispute.phone}</label>
            </div>
          </Col>
          <Col span={24} lg={15}>
            <div css={userDetailsWrapper}>
              <Icon iconName="las la-envelope" />
              <label>{dispute.email}</label>
            </div>
          </Col>
        </Row>
        <Row css={mb({ lg: 27, span: 25 })}>
          <Col span={24}>
            <label css={subtitle}>{getText('dispute')}</label>
          </Col>
          <Col span={4} lg={1}>
            {dispute.reviewUserAvatar ? (
              <img src={dispute.reviewUserAvatar} alt={dispute.reviewUserNames} css={avatar} />
            ) : (
              <Icon iconName="las la-user" size={40} css={noAvatar} />
            )}
          </Col>
          <Col span={20} lg={5}>
            <div css={nameDateWrapper}>
              <p>{dispute.reviewUserNames}</p>
              <label>{formatDefaultDateTime(dispute.reviewCreatedDate)}</label>
            </div>
          </Col>
          <Col span={24} lg={18}>
            <div css={descriptionWrapper}>
              <label>{dispute.reviewTitle}</label>
              <p>{dispute.reviewDescription}</p>
            </div>
          </Col>
        </Row>
        <Row css={mb({ lg: 27, span: 25 })}>
          <Col span={24}>
            <label css={subtitle}>{getText('answer')}</label>
          </Col>
          <Col span={4} lg={1}>
            {dispute.userAvatar ? (
              <img src={dispute.userAvatar} alt={dispute.reviewUserNames} css={avatar} />
            ) : (
              <Icon iconName="las la-user" size={40} css={noAvatar} />
            )}
          </Col>
          <Col span={20} lg={5}>
            <div css={nameDateWrapper}>
              <p>{dispute.userNames}</p>
              <label>{formatDefaultDateTime(dispute.createdDate)}</label>
            </div>
          </Col>
          <Col span={24} lg={18}>
            <div css={descriptionWrapper}>
              <label>{dispute.title}</label>
              <p>{dispute.description}</p>
              <div className="flex">
                {dispute.media &&
                  dispute.media.length > 0 &&
                  dispute.media.map(
                    (item) =>
                      item.url && (
                        <Button
                          key={item.url}
                          type="link"
                          small
                          onClick={() => setIsImageModalVisible(true)}
                          css={disputeImgBtnStyle}>
                          <img src={item.url} alt="Dispute" />
                        </Button>
                      ),
                  )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <DeclineModal
        title={getText('dispute')}
        isVisible={isDeclineModalVisible}
        onCancel={() => setIsDeclineModalVisible(false)}
        onAccept={(reason) => handleEditDisputeStatus(disputeStatus.declined, reason)}
      />
      <CommentModal
        isVisible={isCommentModalVisible}
        onCancel={() => setIsCommentModalVisible(false)}
        onAccept={handleAddCommentToDispute}
      />
      <Modal
        wrapClassName={galleryModal}
        open={isImageModalVisible}
        footer={null}
        destroyOnClose
        width={1300}
        onCancel={() => setIsImageModalVisible(false)}>
        <ImageGalleryPanel
          showNav
          items={dispute.media?.map((item) => item.url && { thumbnail: item.thumbnailUrl, original: item.url })}
        />
      </Modal>
    </>
  ) : (
    <Spin size="large" css={spinner} />
  );
};

export default DisputeDetail;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal } from 'antd';
import { isFunction } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import declineModalIcon from 'assets/images/DeclineModal.svg';
import { reasonArea, modelContentWrapper, declineModalActions, cancelButtonStyle, declineButtonStyle } from './styles';

const DeclineModal = ({ title, isVisible, onAccept, onCancel }) => {
  const { getText } = useTranslations();
  const [reason, setReason] = useState('');

  return (
    <Modal open={isVisible} width={580} zIndex={1100} onCancel={onCancel} css={modelContentWrapper} footer={null}>
      <img src={declineModalIcon} className="iconModal" alt="prop" />
      <p className="section-subtitle">{`${getText('decline')} ${title}`}</p>
      <p className="textReasonStyle">{`${getText('pleaseProvideDeclineReason')} ${title}`}</p>
      <Input.TextArea
        placeholder={getText('declineReason')}
        value={reason}
        css={reasonArea}
        autoSize
        onChange={(e) => setReason(e.target.value)}
      />
      <div css={declineModalActions}>
        <Button
          type="secondary"
          small
          css={cancelButtonStyle}
          onClick={() => {
            onCancel();
            setReason('');
          }}>
          {getText('cancel')}
        </Button>
        <Button
          type="link"
          small
          disabled={!reason}
          onClick={() => {
            isFunction(onAccept) && onAccept(reason);
            setReason('');
          }}
          css={declineButtonStyle}>
          {getText('decline')}
        </Button>
      </div>
    </Modal>
  );
};

DeclineModal.propTypes = {
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  isVisible: PropTypes.bool,
};

export default DeclineModal;

import { commonStyles, theme } from 'styles';

const textColorMap = {
  primary: theme.gray100,
  secondary: theme.white,
  default: theme.gray100,
  danger: theme.white,
  success: theme.white,
  warning: theme.gray100,
  info: theme.gray100,
  link: theme.blue2,
};

const backgroundColorMap = {
  primary: theme.primary_yellow,
  secondary: theme.primary_blue,
  default: theme.white,
  danger: theme.red,
  success: theme.green2,
  warning: theme.warning,
  info: theme.skyblue,
  link: 'transparent',
};

const borderColorMap = {
  default: theme.gray500,
  link: 'transparent',
};

const hoverBackgroundColorMap = {
  primary: theme.primary_hover_btn,
  secondary: theme.primary_blue_hover,
  default: theme.default_hover,
  danger: theme.red_dark,
  success: theme.green,
  warning: theme.warningLight,
  info: theme.skyblue_hover,
  link: 'transparent',
};

const hoverBorderColorMap = {
  default: theme.gray300,
  link: 'transparent',
};

export const buttonContainer = (props, isLoading) => {
  const {
    type = 'primary',
    disabled,
    color,
    borderColor,
    backColor,
    hoverBackColor,
    hoverBorderColor,
    fullWidth,
    small,
  } = props;

  const backgroundColor = theme[backColor] ?? backgroundColorMap[type] ?? theme.primary_yellow;
  const hoverBackgroundColor = theme[hoverBackColor] ?? hoverBackgroundColorMap[type] ?? theme.primary_hover_btn;
  const textColor = theme[color] ?? textColorMap[type] ?? theme.gray100;
  const borderColorDefault = theme[borderColor] ?? borderColorMap[type] ?? backgroundColor;
  const borderColorHover = theme[hoverBorderColor] ?? hoverBorderColorMap[type] ?? hoverBackgroundColor;
  const isLink = type === 'link';

  return [
    {
      cursor: disabled ? 'not-allowed' : 'pointer',
      padding: small ? '0.5em 1em' : '1em 2em',
      width: fullWidth ? '100%' : 'auto',
      minHeight: small ? '1.875rem' : '2.5rem',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: textColor,
      borderRadius: isLink ? 0 : 6,
      lineHeight: 1,
      border: `1px solid ${borderColorDefault}`,
      opacity: isLoading || disabled ? 0.6 : 1,
      backgroundColor,
      userSelect: 'none',
      whiteSpace: 'nowrap',
      transition:
        'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out, width .4s ease-in-out',

      '&:hover': {
        borderColor: borderColorHover,
        backgroundColor: hoverBackgroundColor,
      },

      '&:active': { outline: `2px solid ${theme.skyblueLight}` },

      '& > i': { marginRight: '0.375em' },
    },
    (disabled || isLoading) && {
      '& *': { pointerEvents: 'none' },
    },
    ...commonStyles(props),
  ];
};

export const spinnerBtn = {
  '&.ant-spin': {
    position: 'relative',
    '& .ant-spin-dot': {
      position: 'absolute',
      left: '0.3125rem',
      top: '-0.5rem',
      bottom: 0,
    },
  },
};

export const checkButtonContainer = {
  display: 'inline-flex',
  alignItems: 'center',
  fontWeight: 600,
};

export const iconContainer = {
  marginRight: '0.625em',
};

import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { Col, Row, Spin, Skeleton } from 'antd';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { useTranslations } from '@veraio/strank';
import { Button, Tag, TAG_TYPE, Pagination } from 'components/UIExternal';
import { TabPane, Tabs, Icon, LoadMoreText } from 'components/ui';
import DeclineModal from 'components/ui/DeclineModal';
import CommentModal from 'components/ui/CommentModal';
import { isNil } from '@veraio/core';
import { businessStatus, dealStatus, dealStatusColor, dealStatusInverse, dealRestrictedCategories } from 'enums';
import { formatDefaultDateTime, useParamsInt } from 'utils';
import useError from 'services/errorHandling/useError';
import { addCommentToDeal, updateDealStatus, getDealById, getDealComments } from 'services';
import { DealBusinessAndFacilitiesTab, DealImagesTab, DealInformationTab, DealStatusHistoryTab } from './tabs';
import { mb } from 'styles';
import { pageWrapper, tabs, messageContainer } from './styles';

const DealDetail = () => {
  const { getText, getDynamicTranslation } = useTranslations();
  const { setError } = useError();
  const dealId = useParamsInt('dealId');
  const history = useHistory();
  const [deal, setDeal] = useState({});
  const [isDeclineModalVisible, setIsDeclineModalVisible] = useState(false);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const isPendingBusiness = businessStatus.pending === deal.businessStatusId;
  const isAdmin = hasRole(USER_ROLES.DS_ADMIN);
  const paginationRef = useRef();
  const isRestrictedCategory = dealRestrictedCategories?.includes(deal?.category);
  const [dealComments, setDealComments] = useState(null);

  useEffect(() => {
    fetchDeal();
  }, [dealId]);

  const fetchDealComments = async (options) => {
    const [res, err] = await getDealComments(dealId, options);
    err ? setError(err) : setDealComments(res);
    return res;
  };

  const fetchDeal = async () => {
    const [res, err] = await getDealById(dealId);
    err ? setError(err) : setDeal(res);
  };

  const handleEditDealStatus = async (status, reason) => {
    const [, err] = await updateDealStatus({ id: dealId, status, reason });
    err ? setError(err) : fetchDeal();
    setIsDeclineModalVisible(false);
  };

  const handleAddCommentToDeal = async (comment) => {
    const [, err] = await addCommentToDeal({ id: dealId, comment });
    err ? setError(err) : fetchDeal();
    setIsCommentModalVisible(false);
    fetchDealComments({
      pageSize: 5,
      pageNumber: 1,
    });
  };

  const filterKeys = {
    search: 'searchText',
    status: 'statusId',
    business: 'businessId',
    sorting: 'dealsSorting',
  };

  const businessAddressCommentsContent = dealComments?.items?.length > 0 && (
    <Row css={mb({ lg: 20, span: 30 })}>
      {dealComments.items.map((dealComment) => (
        <Col span={24} key={dealComment.createdDate}>
          <p>
            <span className="bolder-text">{`${dealComment.managerName} [${formatDefaultDateTime(
              dealComment.createdDate,
            )}]: `}</span>
            {dealComment.comment.length > 80 ? (
              <LoadMoreText text={dealComment.comment} limit={80} />
            ) : (
              dealComment.comment
            )}
          </p>
        </Col>
      ))}
    </Row>
  );

  return !isEmpty(deal) ? (
    <div css={pageWrapper} className="inner-content-container">
      <div className="flex deal-name-wrapper">
        <div className="specs-container">
          <Button type="link" onClick={() => history.goBack()} small>
            <Icon iconName="las la-arrow-left" />
          </Button>
          <h2 className="flex flex-center deal-name">{getDynamicTranslation(deal.dealDetails)?.title}</h2>
          <Tag type={dealStatusColor[deal.statusId]} marginRight="1em">
            {getText(dealStatusInverse[deal.statusId])}
          </Tag>
          <Tag type={deal.listed ? TAG_TYPE.GREEN : TAG_TYPE.YELLOW}>
            {deal.listed ? getText('listed') : getText('unlisted')}
          </Tag>
        </div>
        <div className="actions-container">
          <Button
            type="secondary"
            small
            onClick={() =>
              window.open(`${applicationUrls.dealshakerPortal}/deal/preview/${deal.name}`, '_blank').focus()
            }>
            {getText('viewDeal')}
          </Button>
          {(isAdmin || !isRestrictedCategory) && (
            <>
              <Button type="secondary" small onClick={() => setIsCommentModalVisible(true)}>
                {getText('comment')}
              </Button>
              {deal.statusId !== dealStatus.approved && (
                <Button type="secondary" small onClick={() => handleEditDealStatus(dealStatus.approved)}>
                  {getText('approveDeal')}
                </Button>
              )}
              {deal.statusId !== dealStatus.declined && (
                <Button type="danger" small onClick={() => setIsDeclineModalVisible(true)}>
                  {getText('declineDeal')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      {isPendingBusiness && (
        <Row>
          <Col span={24} lg={18} />
          <Col span={24} lg={6} css={messageContainer}>
            <h3>{getText('businessNotApprovedYet')}</h3>
          </Col>
        </Row>
      )}
      <Col lg={{ span: 19, order: 1 }} span={24}>
        <Pagination ref={paginationRef} onChange={fetchDealComments} pageSize={5} filterKeys={filterKeys} />
        {isNil(dealComments) ? <Skeleton count={4} width="24%" height={400} /> : businessAddressCommentsContent}
      </Col>
      <Tabs css={tabs}>
        <TabPane key={1} tab={getText('dealInformation')}>
          <DealInformationTab
            onEditDeal={fetchDeal}
            deal={deal}
            isAdmin={isAdmin}
            isRestrictedCategory={isRestrictedCategory}
          />
        </TabPane>
        <TabPane key={2} tab={getText('businessAndFacilities')}>
          <DealBusinessAndFacilitiesTab deal={deal} />
        </TabPane>
        <TabPane key={3} tab={getText('dealImages')}>
          <DealImagesTab media={deal.media} />
        </TabPane>
        <TabPane key={4} tab={getText('statusHistory')}>
          <DealStatusHistoryTab deal={deal} />
        </TabPane>
      </Tabs>
      <DeclineModal
        title={getText('deal')}
        isVisible={isDeclineModalVisible}
        onCancel={() => setIsDeclineModalVisible(false)}
        onAccept={(reason) => handleEditDealStatus(dealStatus.declined, reason)}
      />
      <CommentModal
        isVisible={isCommentModalVisible}
        onCancel={() => setIsCommentModalVisible(false)}
        onAccept={handleAddCommentToDeal}
      />
    </div>
  ) : (
    <Spin size="large" />
  );
};

export default DealDetail;

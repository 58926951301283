import { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { omit, isFunction, uniqueId, isArray, isString } from 'lodash-es';
import useError from 'services/errorHandling/useError';
import TableHead from './TableHead';
import TableBody from './TableBody';
import { Pagination } from '../Pagination';
import { Skeleton } from '../Loader';
import { NoResults } from './NoResults';
import { useDeepEffect } from '../hooks';
import { tableContainer, containerBody } from './styles';

const omitInternal = (el) => omit(el, '_table');

const Table = forwardRef((props, reference) => {
  const {
    getDataMethod,
    getDataKey = 'items',
    columns,
    pageSize,
    emptyMessage: emptyMessageProp,
    filterBar: FilterBar,
    footerBar,
    className,
    updateOn,
    onSelect,
    hasPagination = true,
    hasSelection: hasSelectionProp,
    showLoader = true,
    renderRow,
    onRowClick,
    rowLink,
    filterKeys,
    requiredFilterKeys,
    defaultQueryParams,
  } = props;

  const [data, setData] = useState(null);
  const [emptyMessage, setEmptyMessage] = useState(emptyMessageProp);
  const hasSelection = isFunction(onSelect) || hasSelectionProp;
  const loaderRef = useRef();
  const paginationRef = useRef();
  const { setError } = useError();

  useImperativeHandle(reference, () => ({
    getData: (params) => paginationRef.current?.applyFilters(params),
    refreshData: () => paginationRef.current?.refresh(),
    selectRow: (row) => selectRow(row),
    getSelected: () => data?.filter((el) => el._table.isSelected).map(omitInternal),
    deselectAll: () => setData((prev) => prev.map((el) => ({ ...el, _table: { ...el._table, isSelected: false } }))),
    changeEmptyMessage: setEmptyMessage,
  }));

  useDeepEffect(
    () => {
      !hasPagination && getData();
    },
    isArray(updateOn) ? updateOn : [updateOn],
  );

  const getData = async (options = {}) => {
    // Wrap all filters into spinner to indicate that results are coming
    showLoader && loaderRef.current?.showLoading();
    const [res, err] = await getDataMethod(options);

    showLoader && loaderRef.current?.hideLoading();
    if (err) return setError(err);

    setData(
      res
        ? res[getDataKey].map((el) => ({
            ...el,
            _table: { isSelected: false, uuid: uniqueId() },
          }))
        : [],
    );

    return res;
  };

  const clearTableData = () => {
    setData([]);
    paginationRef.current?.resetFilters();
  };

  const selectRow = (value, row) => {
    // If row is not passed mark all rows from the table
    const compare = (el) => !row || el._table.uuid === row._table.uuid;

    setData((prev) => {
      const newRows = prev.map((el) => (compare(el) ? { ...el, _table: { ...el._table, isSelected: value } } : el));

      isFunction(onSelect) && onSelect(newRows.filter((el) => el._table.isSelected).map(omitInternal));

      return newRows;
    });
  };

  const tableDataProps = {
    getData,
    data,
    setData,
    selectRow,
    setEmptyMessage,
    clearTableData,
    paginationRef,
  };

  const filterProps = { filterKeys, requiredFilterKeys, defaultQueryParams };

  return (
    <>
      {FilterBar && <FilterBar {...tableDataProps} {...filterProps} />}
      <div css={tableContainer} {...(isString(className) && { className })}>
        <TableHead
          hasSelection={hasSelection}
          columns={columns}
          allSelected={data?.every((el) => el?._table?.isSelected)}
          {...tableDataProps}
        />
        {data?.length === 0 ? (
          <NoResults emptyMessage={emptyMessage} />
        ) : !data ? (
          <Skeleton count={pageSize ?? 10} height={30} marginBottom={12} />
        ) : (
          <div css={containerBody}>
            <TableBody
              hasSelection={hasSelection}
              data={data}
              columns={columns}
              renderRow={renderRow}
              onRowClick={onRowClick}
              rowLink={rowLink}
              {...tableDataProps}
            />
          </div>
        )}
        {hasPagination && (
          <Pagination ref={paginationRef} justify="center" onChange={getData} {...filterProps} {...props} />
        )}
        {footerBar && footerBar(tableDataProps)}
      </div>
    </>
  );
});

Table.propTypes = {
  getDataMethod: PropTypes.func,
  columns: PropTypes.array,
  pageSize: PropTypes.number,
  emptyMessage: PropTypes.string,
  filterBar: PropTypes.func,
  footerBar: PropTypes.func,
  updateOn: PropTypes.any,
  className: PropTypes.string,
  getDataKey: PropTypes.string,
  onSelect: PropTypes.func,
  hasPagination: PropTypes.bool,
  hasSelection: PropTypes.bool,
  pageSizes: PropTypes.array,
  showLoader: PropTypes.bool,
  renderRow: PropTypes.func,
  onRowClick: PropTypes.func,
  rowLink: PropTypes.any,
  withPageDropdown: PropTypes.bool,
  filterKeys: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  requiredFilterKeys: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultQueryParams: PropTypes.object,
  dropDownListOptionsOnTop: PropTypes.bool,
};

export default Table;

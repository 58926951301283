import { Link } from 'react-router-dom';
import { getText } from '@veraio/strank';
import { CRYPTO_CURRENCIES } from '@oneecosystem/dealshaker-core';
import { Tag, CopyText } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { PAYMENT_PROVIDERS_INVERT, TRANSACTION_STATUS_COLOR, TRANSACTION_STATUS_INVERSE } from 'enums';
import { textLinkStyle } from './styles';
import { PriceLabel } from 'components/shared';

export const getPaymentsColumns = () => [
  {
    name: getText('id'),
    width: 110,
    render: (row) => (
      <div>
        <p>{row.transactionId}</p>
        {!!row.externalTransactionId && row.externalTransactionId !== '0' && (
          <CopyText css={{ fontSize: '0.7rem', display: 'flex', alignItems: 'center' }}>
            {row.externalTransactionId}
          </CopyText>
        )}
      </div>
    ),
  },
  {
    name: getText('createdDate'),
    value: 'createdDate',
    dateTimeFormat: 'DD-MM-YYYY HH:mm',
    width: 180,
  },
  {
    name: getText('sender'),
    render: (row) => row.senderEmail && <p>{row.senderEmail}</p>,
  },
  {
    name: getText('receiver'),
    render: (row) => row.receiverEmail && <p>{row.receiverEmail}</p>,
  },
  {
    name: getText('amount'),
    render: (row) => (
      <PriceLabel
        fiat={row.originalAmount}
        fiatOriginCurrency={row.originalCurrency}
        fiatDesiredCurrency={row.originalCurrency}
        crypto={row.oneCoinAmount || row.oespAmount}
        cryptoDesiredCurrency={row.oneCoinAmount ? CRYPTO_CURRENCIES.ONE.code : CRYPTO_CURRENCIES.OESP.code}
        cryptoOriginCurrency={row.oneCoinAmount ? CRYPTO_CURRENCIES.ONE.code : CRYPTO_CURRENCIES.OESP.code}
        fiatOnly={false}
      />
    ),
    width: 200,
  },
  {
    name: getText('paymentProvider'),
    render: (row) => getText(PAYMENT_PROVIDERS_INVERT[row.paymentProviderId]),
    width: 150,
  },
  {
    name: getText('status'),
    render: (row) => (
      <Tag type={TRANSACTION_STATUS_COLOR[row.statusId]}>{getText(TRANSACTION_STATUS_INVERSE[row.statusId])}</Tag>
    ),
    width: 130,
  },
  {
    render: (row) => (
      <Link to={`/transactions/${row.transactionId}/`} css={textLinkStyle}>
        <Icon iconName="lar la-eye" size={20} />
      </Link>
    ),
    width: 55,
  },
];

export const filterKeys = {
  orderId: 'orderId',
  externalTransactionId: 'externalTransactionId',
  merchantEmail: 'merchantEmail',
  buyerEmail: 'buyerEmail',
  creationDateFrom: 'creationDateFrom',
  creationDateTo: 'creationDateTo',
  paymentProviderId: 'paymentProviderId',
};

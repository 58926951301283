import { useLayoutEffect, useState, useImperativeHandle } from 'react';
import { isFunction, isPlainObject, isArray, pick, isNil } from '@veraio/core';
import { useUrlParams, useDeepEffect } from '../hooks';

const extractParams = (queryParams, keys) => {
  const parametersKeys = isPlainObject(keys) ? Object.values(keys) : isArray(keys) ? keys : [keys];
  return { pageNumber: queryParams.pageNumber, pageSize: queryParams.pageSize, ...pick(queryParams, parametersKeys) };
};

const defaultPageSizes = [10, 20, 50];

export const usePagination = ({
  onChange,
  pageNumber: pageNumberProp,
  pageSize: pageSizeProp,
  pageSizes,
  filterKeys,
  requiredFilterKeys,
  defaultQueryParams,
  ref,
}) => {
  const { queryParams, setQueryParams, clearQueryParams } = useUrlParams();
  const [totalResults, setTotalResults] = useState(null);

  const queryPageNumber = queryParams.pageNumber ?? 1;
  const queryPageSize = queryParams.pageSize ?? pageSizes?.at(0) ?? defaultPageSizes.at(0);
  const totalPages = Math.ceil(totalResults / queryPageSize) || 1;

  const params = extractParams(queryParams, filterKeys);
  const requiredParams = extractParams(queryParams, requiredFilterKeys);
  const hasRequiredParams = !Object.values(requiredParams).some((el) => isNil(el) || el === '');

  useImperativeHandle(ref, () => ({
    pageNumber: queryPageNumber,
    pageSize: queryPageSize,
    totalResults,
    changePageNumber: handlePageChange,
    changePageSize: handlePageSizeChange,
    changeTotalResults: handleTotalResultsChange,
    applyFilters: handleApplyFilters,
    resetFilters: handleResetFilters,
    refresh: callOnChange,
  }));

  useLayoutEffect(() => {
    setQueryParams({
      ...defaultQueryParams,
      ...queryParams,
      pageNumber: pageNumberProp ?? queryPageNumber,
      pageSize: pageSizeProp ?? queryPageSize,
    });
  }, [pageNumberProp, pageSizeProp]);

  useDeepEffect(() => {
    // Pagination could be used with or without filters. So we need to call onChange method to fetch the first page
    // If there are some required params this effect will wait those params to be filled and after that will call onChange
    hasRequiredParams && callOnChange();
  }, [params]);

  const handlePageChange = (newPageNumber) => setQueryParams({ pageNumber: newPageNumber, pageSize: queryPageSize });

  const handlePageSizeChange = (newPageSize) => setQueryParams({ pageNumber: queryPageNumber, pageSize: newPageSize });

  const handleTotalResultsChange = (res) => {
    const total = res?.totalCount ?? 0;
    setTotalResults(total);
    const numberOfPages = Math.ceil(total / queryPageSize) || 1;
    numberOfPages < queryPageNumber && handlePageChange(numberOfPages);
  };

  const handleApplyFilters = (newParams) => setQueryParams({ ...newParams, pageNumber: 1 });

  const handleResetFilters = () => {
    setQueryParams({ pageNumber: 1 });
    clearQueryParams(extractParams(queryParams, filterKeys));
  };

  const callOnChange = async () => {
    if (!isFunction(onChange)) return;
    const res = await onChange(params);
    handleTotalResultsChange(res);
  };

  return {
    totalResults,
    totalPages,
    pageNumber: queryPageNumber,
    pageSize: queryPageSize,
    handlePageChange,
    handlePageSizeChange,
    defaultPageSizes,
  };
};

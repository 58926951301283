import { useState } from 'react';
import { useTranslations } from '@veraio/strank';

export const LoadMoreText = ({ text, limit }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { getText } = useTranslations();

    // Function to toggle the "expanded" state
    const toggleExpansion = () => {
      setIsExpanded(!isExpanded);
    };

    // Decide what part of the text to show
    const displayedText = isExpanded ? text : `${text.slice(0, limit)} ... `;

    return (
      <>
        <span>{displayedText}</span>
        <button onClick={toggleExpansion} style={{ fontWeight: 'bold' }}>
          {isExpanded ? '. ' + getText('showLess') : ' ' + getText('loadMore')}
        </button>
      </>
    );
  };

  export default LoadMoreText;

import { theme } from 'styles';

export const tabsContainer = ({ useBlackUnderline }) => ({
  '&.ant-tabs-top': {
    '& > [role="tablist"]': {
      marginBottom: 0,
    },
    '& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
      color: theme.gray100,
    },
    '& .ant-tabs-tab:hover': {
      color: theme.gray100,
    },
    '& .ant-tabs-tab': {
      color: theme.gray300,
    },
    '& .ant-tabs-ink-bar': {
      background: useBlackUnderline ? theme.gray100 : theme.primary_yellow,
    },
  },
});

import { TAG_TYPE } from 'components/UIExternal';
import { invert } from 'lodash-es';

export const TRANSACTION_STATUS = {
  pending: 1,
  failed: 2,
  created: 3,
  successful: 4,
  reverted: 5,
  cancelled: 6,
  partiallyPaid: 8,
};

export const TRANSACTION_STATUS_COLOR = {
  [TRANSACTION_STATUS.pending]: TAG_TYPE.WARNING,
  [TRANSACTION_STATUS.failed]: TAG_TYPE.RED,
  [TRANSACTION_STATUS.created]: TAG_TYPE.BLUE,
  [TRANSACTION_STATUS.successful]: TAG_TYPE.GREEN,
  [TRANSACTION_STATUS.reverted]: TAG_TYPE.GREEN_LIGHT,
  [TRANSACTION_STATUS.cancelled]: TAG_TYPE.RED_LIGHT,
  [TRANSACTION_STATUS.partiallyPaid]: TAG_TYPE.YELLOW,
};

export const TRANSACTION_STATUS_INVERSE = invert(TRANSACTION_STATUS);

import { useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { getText } from '@veraio/strank';
import { Col, Row } from 'antd';
import { Button, Table } from 'components/UIExternal';
import { Icon, LoadMoreText } from 'components/ui';
import { mb } from 'styles';
import CommentModal from 'components/ui/CommentModal';
import { setError, addTransactionComment, getTransactionDetails } from 'services';
import { formatDefaultDateTime, mockTableRes, useParamsInt } from 'utils';
import { transactionDetailsHistoryTable, transactionDetailsGenericInfo } from './config';
import { informationWrapper, navigationWrapper, subTitle } from './styles';

const TransactionDetails = () => {
  const history = useHistory();
  const transactionId = useParamsInt('transactionId');
  const [transactionDetails, setTransactionDetails] = useState();
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);

  useEffect(() => {
    fetchTransactionDetails(transactionId);
  }, [transactionId]);

  const fetchTransactionDetails = async () => {
    const [res, err] = await getTransactionDetails(transactionId);
    if (err) return setError(err);

    setTransactionDetails(res);
  };

  const handleAddComment = async (comment) => {
    const [, err] = await addTransactionComment(transactionId, comment);
    if (err) return setError(err);

    fetchTransactionDetails();
    setIsCommentModalVisible(false);
  };

  return (
    <>
      <div className="inner-content-container">
        <div css={navigationWrapper}>
          <div className="specs-container">
            <Button type="link" onClick={() => history.goBack()} small>
              <Icon iconName="las la-arrow-left" />
            </Button>
            <h2 className="flex flex-center address-name">{getText('paymentDetails')}</h2>
          </div>

          <div className="actions-container">
            <Button type="secondary" small onClick={() => setIsCommentModalVisible(true)}>
              {getText('comment')}
            </Button>
          </div>

          <Row>
            <Col lg={12}>
              <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
                <Col lg={24} span={24}>
                  <label css={subTitle}>{getText('Pyament Information')}</label>
                </Col>
                <Col lg={24} span={24}>
                  {transactionDetails?.transactionInfo && (
                    <Row>
                      {transactionDetailsGenericInfo().map((el) => (
                        <Fragment key={el.key}>
                          <Col lg={14} span={24}>
                            <label>{el.label}</label>
                          </Col>
                          <Col lg={10} span={24}>
                            <p>
                              {el.render
                                ? el.render(transactionDetails?.transactionInfo)
                                : transactionDetails?.transactionInfo?.[el.key] ?? 'N/A'}
                            </p>
                          </Col>
                        </Fragment>
                      ))}
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>

            <Col lg={12}>
              <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
                <Col lg={24} span={24}>
                  <label css={subTitle}>{getText('Comments')}</label>
                </Col>
                {transactionDetails?.transactionInfo &&
                  transactionDetails?.comments?.map((transactionComment, ind) => (
                    <Col span={24} key={ind}>
                      <p>
                        <span className="bolder-text">{`${transactionComment.createdBy} [${formatDefaultDateTime(
                          transactionComment.createdDate,
                        )}]: `}</span>
                        {transactionComment.comment.length > 100 ?
                        <LoadMoreText text={transactionComment.comment} limit={100} /> :
                        transactionComment.comment}
                      </p>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </div>
        {!!transactionDetails && (
          <>
            <label css={subTitle}>{getText('Transactions History')}</label>
            <Table
              getDataMethod={mockTableRes(transactionDetails?.history)}
              columns={transactionDetailsHistoryTable()}
            />
          </>
        )}
      </div>
      <CommentModal
        isVisible={isCommentModalVisible}
        onCancel={() => setIsCommentModalVisible(false)}
        onAccept={handleAddComment}
      />
    </>
  );
};

export default TransactionDetails;

import { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Button, getTextChangeProps, Pagination } from 'components/UIExternal';
import { useTranslations } from '@veraio/strank';
import { isEmpty } from 'lodash-es';
import { isMobile } from 'react-device-detect';
import { AddressCard } from 'components/shared';
import { Col, Row, Skeleton } from 'antd';
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import { isNil } from '@veraio/core';
import { UploadedFiles, LoadMoreText } from 'components/ui';
import { getBusinessComments } from 'services/api/businesses';
import {
  BusinessMainInformationPreview,
  BusinessAdditionalInformationPreview,
  BusinessAddressPreview,
  ContactInformationPreview,
  BusinessMainInformationEdit,
  BusinessAdditionalInformationEdit,
  BusinessAddressEdit,
  ContactInformationEdit,
} from 'components/screens/BusinessDetailPage';
import { formatDefaultDateTime } from 'utils';
import { mb } from 'styles';
import { informationWrapper, subTitle, specificBusinessInfoWrapper } from './styles';
import { editFormSectionBtn } from './styles';
import useError from 'services/errorHandling/useError';


export const BusinessInformationTab = forwardRef(({
  business,
  handleEditBusiness,
  businessWithAddress,
  oldData,
  newData
}, ref) => {
  const { getText } = useTranslations();
  const [isEditingMainInfo, setIsEditingMainInfo] = useState(false);
  const [isEditingAdditionalInfo, setIsEditingAdditionalInfo] = useState(false);
  const [isEditingBusinessAddress, setIsEditingBusinessAddress] = useState(false);
  const [isEditingContactInfo, setIsEditingContactInfo] = useState(false);
  const paginationRef = useRef();
  const [ businessComments, setBusinessComments ] = useState(null);
  const { setError } = useError();

  useImperativeHandle(ref, () => ({
    refresh: () => paginationRef.current?.refresh(),
  }));

  const filterKeys = {
    search: 'searchText',
    status: 'statusId',
    business: 'businessId',
    sorting: 'dealsSorting',
  };
  const handleEditMainInformation = () => {
    setIsEditingMainInfo((prev) => !prev);
  };

  const fetchBusinessComments = async options => {
    const [res, err] = await getBusinessComments(businessWithAddress.id, options);
    err ? setError(err) : setBusinessComments(res);
    return res;
  };

  const handleEditBusinessAddress = () => {
    setIsEditingBusinessAddress((prev) => !prev);
  };

  const handleEditAdditionalInformation = () => {
    setIsEditingAdditionalInfo((prev) => !prev);
  };

  const handleEditContactInformation = () => {
    setIsEditingContactInfo((prev) => !prev);
  };

  const textChangeProps = getTextChangeProps(oldData, newData);

  const businessCommentsContent =
    businessComments?.items?.length > 0 && (
      <Row css={mb({ lg: 20, span: 30 })}>
        {businessComments.items.map((businessComment) => (
          <Col span={24} key={businessComment.createdDate}>
            <p>
              <span className="bolder-text">{`${businessComment.managerName} [${formatDefaultDateTime(
                businessComment.createdDate,
              )}]: `}</span>
              {businessComment.comment.length > 80 ?
                <LoadMoreText text={businessComment.comment} limit={80} /> :
                businessComment.comment}
            </p>
          </Col>
        ))}
      </Row>
  );

  return (
    <>
      <Col lg={{ span: 19, order: 1 }} span={24}>
        <Pagination ref={paginationRef} onChange={fetchBusinessComments} pageSize={5} filterKeys={filterKeys} />
        {isNil(businessComments) ? <Skeleton count={4} width="24%" height={400} /> : businessCommentsContent}
      </Col>
      {/* here starts the business components */}
      <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
        <Col lg={24} span={24}>
          <label css={subTitle}>{getText('mainInformation')}</label>
          <label css={subTitle}>{getText('mainInformation')}</label>
          <Button type="default" css={editFormSectionBtn} onClick={handleEditMainInformation}>
            {isEditingMainInfo ? <CloseOutlined /> : <EditOutlined />}
          </Button>
        </Col>
        {isEditingMainInfo ? (
          <BusinessMainInformationEdit
            business={business}
            onCancel={() => setIsEditingMainInfo(false)}
            onEdit={handleEditBusiness}
          />
        ) : (
          <BusinessMainInformationPreview business={business} textChange={textChangeProps} />
        )}
      </Row>
      <Row css={[specificBusinessInfoWrapper, mb({ lg: 36, span: 30 })]}>
        <Col lg={24} span={24}>
          <label css={subTitle}>{getText('additionalInformation')}</label>
          <Button type="default" css={editFormSectionBtn} onClick={handleEditAdditionalInformation}>
            {isEditingAdditionalInfo ? <CloseOutlined /> : <EditOutlined />}
          </Button>
        </Col>
        {isEditingAdditionalInfo ? (
          <BusinessAdditionalInformationEdit
            business={business}
            onCancel={() => setIsEditingAdditionalInfo(false)}
            onEdit={handleEditBusiness}
          />
        ) : (
          <BusinessAdditionalInformationPreview business={business} textChange={textChangeProps} />
        )}
      </Row>
      <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
        <Col lg={24} span={24}>
          <label css={subTitle}>{getText('contactInformation')}</label>
          <Button type="default" css={editFormSectionBtn} onClick={handleEditContactInformation}>
            {isEditingContactInfo ? <CloseOutlined /> : <EditOutlined />}
          </Button>
        </Col>
        {isEditingContactInfo ? (
          <ContactInformationEdit
            business={business}
            onCancel={() => setIsEditingContactInfo(false)}
            onEdit={handleEditBusiness}
          />
        ) : (
          <ContactInformationPreview business={business} textChange={textChangeProps} />
        )}
      </Row>
      <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
        <Col lg={24} span={24}>
          <label css={subTitle}>{getText('businessAddress')}</label>
          <Button type="default" css={editFormSectionBtn} onClick={handleEditBusinessAddress}>
            {isEditingBusinessAddress ? <CloseOutlined /> : <EditOutlined />}
          </Button>
        </Col>
        {isEditingBusinessAddress ? (
          <BusinessAddressEdit
            business={business}
            onCancel={() => setIsEditingBusinessAddress(false)}
            onEdit={handleEditBusiness}
          />
        ) : (
          <BusinessAddressPreview business={business} textChange={textChangeProps} />
        )}
      </Row>
      {!isEmpty(business.media) && (
        <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
          <Col lg={24} span={24}>
            <label css={subTitle}>{getText('uploadedFiles')}</label>
          </Col>
          <UploadedFiles media={business.media} css={mb({ lg: 10 })} />
        </Row>
      )}
      <Row>
        <Col lg={24} span={24} style={{ paddingLeft: !isMobile && 16 }}>
          <label css={subTitle}>{getText('redemptionLocations')}</label>
        </Col>
      </Row>
      <Row css={mb({ lg: 30, span: 20 })} gutter={[24, 24]}>
        {businessWithAddress?.businessAddresses?.map((_, ind) => (
          <Col className="gutter-row" lg={8} span={24} key={ind}>
            <AddressCard oldData={businessWithAddress?.currentBusinessInformation}
            newData={businessWithAddress?.newBusinessInformation} />
          </Col>
        ))}
      </Row>
    </>
  );
});

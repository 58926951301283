import { getReq, putReq } from 'services/axios/makeRequest';
import { getAllTransactionsParams, getTransactionCommentsParams } from 'services/models';

const baseUrl = `${apiUrls.dealshakerAdmin}/api/Financials`;

export const getAllTransactions = (options) =>
  getReq(`${baseUrl}/ListTransactions?${getAllTransactionsParams(options)}`);

export const getTransactionDetails = (transactionId) =>
  getReq(`${baseUrl}/TransactionDetails?transactionId=${transactionId}`);

export const addTransactionComment = (transactionId, comment) =>
  putReq(`${baseUrl}/AddTransactionComment`, { transactionId, comment });

export const getTransactionComments = async (id, options) =>
  getReq(`${baseUrl}/GetTransactionComments/${id}?${getTransactionCommentsParams(options)}`);

import queryString from 'query-string';
import { isNil, pick } from 'lodash-es';

export const getAllTransactionsParams = (options) => {
  const {
    orderId,
    externalTransactionId,
    merchantEmail,
    buyerEmail,
    creationDateFrom,
    creationDateTo,
    paymentProviderId,
  } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(orderId && orderId.toString().length && { orderId }),
      ...(paymentProviderId && paymentProviderId.toString().length && { paymentProviderId }),
      ...(externalTransactionId && externalTransactionId.toString().length && { externalTransactionId }),
      ...(merchantEmail && merchantEmail?.length && { merchantEmail }),
      ...(buyerEmail && buyerEmail?.length && { buyerEmail }),
      ...(!isNil(creationDateFrom) && { creationDateFrom }),
      ...(!isNil(creationDateTo) && { creationDateTo }),
    },
    { arrayFormat: 'index' },
  );
};

export const getTransactionCommentsParams = (options) => {
  const {
    sort = { sortField: 'startDate', sortOption: 'asc' },
    pageSize,
    pageNumber,
  } = options;

  const mapSort = (newSort) => ({
    sortField: newSort.sortField === 'modifiedDate' ? 'updatedDate' : newSort.sortField,
    sortOption: newSort.sortOption,
  });

  return queryString.stringify(
    {
      ...(pageNumber && { pageNumber }),
      ...(pageSize && { pageSize }),
      ...(sort && mapSort(sort)),
    },
    { arrayFormat: 'index' },
  );
};

import { theme } from 'styles';

export const modelContentWrapper = {
  '& .ant-modal-body': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .ant-btn': { marginTop: '1.5em' },
    '& .iconModal': { marginBottom: '1.5em' },
    '& .textReasonStyle': { marginBottom: '2.625em' },
    '& .modal-buttons-container': { width: '50%', justifyContent: 'space-around' },
    [theme.Desktop]: {
      padding: '2.5em 5em !important',
    },
  },
};

export const reasonArea = {
  [theme.Desktop]: {
    minHeight: '11rem !important',
  },
};

export const cancelButtonStyle = {
  fontWeight: 600,
  marginRight: '0.75em',
};

export const declineModalActions = {
  display: 'flex',
  marginTop: '1.5em',
};

export const declineButtonStyle = {
  fontSize: '1rem',
  color: theme.red,
  '&:hover': {
    borderColor: 'transparent',
  },
};

import { getText } from '@veraio/strank';

export const CONFIG_GROUP = {
  DEAL_FEE: 'DEAL_FEE',
  DISTRIBUTION_FEE: 'DISTRIBUTION_FEE',
  MOBILE_VERSION: 'MOBILE_VERSION',
  WALLETS_FEE: 'WALLETS_FEE',
  EXTERNAL_PAYMENT_PROVIDERS: 'EXTERNAL_PAYMENT_PROVIDERS',
  CLEARING_AND_SETTLEMENT: 'CLEARING_AND_SETTLEMENT',
};

export const DISTRIBUTION_FEE = {
  DEALS_FEE_FOR_COMPANY: 'DEALS_FEE_FOR_COMPANY',
  DEALS_FEE_FOR_COUNTRY_MANAGER: 'DEALS_FEE_FOR_COUNTRY_MANAGER',
  DEALS_FEE_FOR_AREA_MANAGER: 'DEALS_FEE_FOR_AREA_MANAGER',
  DEALS_FEE_FOR_REGIONAL_DIRECTOR: 'DEALS_FEE_FOR_REGIONAL_DIRECTOR',
  DEALS_FEE_FOR_SPONSOR: 'DEALS_FEE_FOR_SPONSOR',
  DEALS_FEE_FOR_TO_THE_MARKETING_BUDGET_OF_THE_PLATFORM: 'DEALS_FEE_FOR_TO_THE_MARKETING_BUDGET_OF_THE_PLATFORM',
  DEALS_FEE_FOR_ADDITIONAL_SERVICES_WALLET: 'DEALS_FEE_FOR_ADDITIONAL_SERVICES_WALLET',
};

export const DEAL_FEE = {
  WITH_90_100_PERCENT_CRYPTO: 'DEALS_FEE_WITH_90-100_PERCENT_CRYPTO',
  WITH_70_89_PERCENT_CRYPTO: 'DEALS_FEE_WITH_70-89_PERCENT_CRYPTO',
  WITH_50_69_PERCENT_CRYPTO: 'DEALS_FEE_WITH_50-69_PERCENT_CRYPTO',
  WITH_30_49_PERCENT_CRYPTO: 'DEALS_FEE_WITH_30-49_PERCENT_CRYPTO',
  WITH_10_29_PERCENT_CRYPTO: 'DEALS_FEE_WITH_10-29_PERCENT_CRYPTO',
  WITH_0_9_PERCENT_CRYPTO: 'DEALS_FEE_WITH_0-9_PERCENT_CRYPTO',
};

export const MOBILE_VERSION = {
  MOBILE_VERSION_MERCHANT_ANDROID: 'MOBILE_VERSION_MERCHANT_ANDROID',
  MOBILE_VERSION_MERCHANT_IOS: 'MOBILE_VERSION_MERCHANT_IOS',
  MOBILE_VERSION_BUYER_ANDROID: 'MOBILE_VERSION_BUYER_ANDROID',
  MOBILE_VERSION_BUYER_IOS: 'MOBILE_VERSION_BUYER_IOS',
};

export const WALLETS_FEE = {
  COMPANY_WALLET_ID: 'COMPANY_WALLET_ID',
  MARKETING_WALLET_ID: 'MARKETING_WALLET_ID',
  ADDITIONAL_SERVICE_WALLET_ID: 'ADDITIONAL_SERVICE_WALLET_ID',
  BACKUP_WALLET_ID: 'BACKUP_WALLET_ID',
  SETTLEMENT_ONELIFE_ACCOUNT_ID: 'SETTLEMENT_ONELIFE_ACCOUNT_ID',
};

export const EXTERNAL_PAYMENT_PROVIDERS = {
  COINPAYMENTS_CURRENCY: 'COINPAYMENTS_CREATE_PAYMENT_REQUEST_CURRENCY',
  COINPAYMENTS_REQUEST_TIMEOUT: 'COINPAYMENTS_CREATE_PAYMENT_REQUEST_TIMEOUT_IN_SECONDS',
};

export const SETTLEMENTS_ROLE = {
  SETTLEMENT_MERCHANT_PERIOD: 'SETTLEMENT_MERCHANT_PERIOD',
  SETTLEMENT_CM_PERIOD: 'SETTLEMENT_CM_PERIOD',
  SETTLEMENT_AM_PERIOD: 'SETTLEMENT_AM_PERIOD',
  SETTLEMENT_RD_PERIOD: 'SETTLEMENT_RD_PERIOD',
  SETTLEMENT_SPONSOR_PERIOD: 'SETTLEMENT_SPONSOR_PERIOD',
  SETTLEMENT_COMPANY_PERIOD: 'SETTLEMENT_COMPANY_PERIOD',
  SETTLEMENT_MARKETING_PERIOD: 'SETTLEMENT_MARKETING_PERIOD',
  SETTLEMENT_ADDITIONAL_SERVICES_PERIOD: 'SETTLEMENT_ADDITIONAL_SERVICES_PERIOD',
};

export const CLEARING_AND_SETTLEMENT = {
  BASIC_CLEARING_DAYS: 'BASIC_CLEARING_DAYS',
};

export const CLEARING_AND_SETTLEMENT_PERIOD = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
};

export const RISK_LEVEL = {
  high: 'high',
  moderate: 'moderate',
  low: 'low',
  no: 'no',
};

export const settlementsPeriodSelect = () =>
  Object.entries(CLEARING_AND_SETTLEMENT_PERIOD).map(([label, value]) => ({
    label: getText(label),
    value,
  }));

import { getReq, patchReq, postReq } from 'services/axios/makeRequest';
import { getAllDisputesParams } from 'services/models/disputes';

const baseUrl = `${apiUrls.dealshakerAdmin}/api/Disputes`;

export const getAllDisputes = async (options) => {
  const [res, err] = await getReq(`${baseUrl}/List?${getAllDisputesParams(options)}`);
  return [res, err];
};

export const getDisputeById = (disputeId) => getReq(`${baseUrl}/Get/${disputeId}`);

export const updateDisputeStatus = (data) => patchReq(`${baseUrl}/UpdateStatus`, data);

export const addCommentToDispute = (data) => postReq(`${baseUrl}/Comment`, data);

export const getDisputeComments = async (id, options) => {
  const [res, err] = await getReq(`${baseUrl}/GetDisputeComments/${id}?${getAllDisputesParams(options)}`);
  return [res, err];
};
